<template>
  <div>
    <div class="body">
      <div>
        <div class="scope_hide" v-for="it in spoceList" :key="it.id">
          <div class="hide_one">
            <span>字段:</span>
            <el-select
              style="width: 100%"
              filterable
              allow-create
              default-first-option
              v-model="it.key"
              placeholder="请选择"
              @change="addfield()"
            >
              <el-option
                v-for="item in inputName"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="hide_one">
            <span>关系:</span>
            <el-select
              style="width: 100%"
              v-model="it.o"
              placeholder="请选择"
              @change="addfield()"
            >
              <el-option
                v-for="item in relationshipList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="hide_one">
            <span>权限:</span>
            <el-select
              v-model="it.v"
              filterable
              allow-create
              default-first-option
              placeholder="请选择权限"
              @change="addfield()"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <i
            class="el-icon-remove"
            style="font-size: 18px; color: red; margin-left: 5px"
            @click="delScope(it, item)"
          ></i>
        </div>
        <el-button
          icon="el-icon-plus"
          style="width: 95%; margin: 20px; border-style: dashed; color: #888"
          @click="addScope()"
          >添加字段</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const roleScopeDelete = api()('/roleScope.delete.json');

export default {
  props: {
    inputName: Array,
    spoceList: Array
  },
  data () {
    return {
      relationship: '',
      data: [
        {
          name: '',
          content: [
            {
              key: '',
              o: '',
              v: ''
            }
          ]
        }
      ],
      relationshipList: [
        {
          value: 'f_eq',
          label: '等于'
        },
        {
          value: 'f_gt',
          label: '大于'
        },
        {
          value: 'f_lt',
          label: '小于'
        },
        {
          value: 'f_in',
          label: '包含'
        },
        {
          value: 'f_not_in',
          label: '不包含'
        },
        {
          value: 'f_find_in_set',
          label: '字符中含有'
        },
        {
          value: 'f_like',
          label: '模糊匹配'
        },
        {
          value: 'f_regexp',
          label: '正则'
        }
      ],
      options: [
        {
          value: '$TRUE',
          label: '布尔值-TRUE'
        },
        {
          value: '$FALSE',
          label: '布尔值-FALSE'
        },
        {
          value: '$selfUserCode',
          label: '用户-当前用户'
        },
        {
          value: '$cooperativeMaker',
          label: '用户-合作创客'
        },
        {
          value: '$agentUserCode',
          label: '用户-代理用户'
        },
        {
          value: '$selfEnterpriseCode',
          label: '企业-当前企业'
        },

        {
          value: '$selfRelationCPath',
          label: '当前项目(正则)'
        },
        {
          value: '$selfRelationEPath',
          label: '当前企业(正则)'
        },

        {
          value: '$agentEnterpriseCode',
          label: '企业-代理的企业'
        },
        {
          value: '1',
          label: '结算中心-陕西'
        },
        {
          value: '2',
          label: '结算中心-山东'
        },
        {
          value: '3',
          label: '结算中心-江西'
        },
        {
          value: 'custom',
          label: '用户类型-自定义用户'
        },
        {
          value: 'system',
          label: '用户类型-系统用户'
        }
      ]
    };
  },

  mounted () { },
  methods: {
    addfield () {
      this.$emit('childByValue', this.spoceList);
    },
    addScope () {
      console.log(this);
      this.spoceList.push({ key: '', o: '', v: '' });
      this.$emit('childByValue', this.spoceList);
    },
    async delScope (it) {
      this.spoceList = this.spoceList.filter(_it => _it != it);
      this.$emit('childByValue', this.spoceList);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  border: 1px solid #eee;
  padding: 10px 0;
  margin-bottom: 20px;
}
.scope_hide {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .hide_one {
    display: flex;
    align-items: center;
    span {
      width: 60px;
      text-align: center;
    }
  }
}
</style>
<style>
.block_input input {
  background-color: #f7f7f7;
  text-align: center;
}
</style>
